

















































































































































































































































import {
  defineComponent,
  ref,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  SfBadge,
  SfBreadcrumbs,
  SfButton,
  SfCollectedProduct,
  SfHeading,
  SfImage,
  SfLoader,
  SfNotification,
  SfPrice,
  SfProperty,
  SfQuantitySelector,
} from '@storefront-ui/vue';

import SvgImage from '~/components/General/SvgImage.vue';
import { Breadcrumb } from '~/modules/catalog/types';
import { useCartView } from '~/modules/checkout/composables/useCartView';

import CouponCode from '../../../components/CouponCode.vue';

export default defineComponent({
  name: 'CartPage',
  components: {
    SfBreadcrumbs,
    SfLoader,
    SfNotification,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfCollectedProduct,
    SfQuantitySelector,
    SfBadge,
    CouponCode,
    SvgImage,
    SfImage,
  },
  setup() {
    const cartView = useCartView();
    const { localePath, app: { i18n } } = useContext();
    const router = useRouter();

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t('Home') as string,
        link: localePath('/'),
      },
      {
        text: i18n.t('My Cart') as string,
        link: localePath('/cart'),
      },
    ]);

    const handleHomeClick = async () => {
      await router.push(localePath('/'));
    };

    return {
      ...cartView,
      breadcrumbs,
      handleHomeClick,
    };
  },
});
